import {
	Module,
	VuexModule,
	getModule,
	Mutation,
	Action,
} from "vuex-module-decorators";
import { store } from "@/store";
import { IAgent } from "vue-advanced-chat";
import { socket } from "@/main";
import { coreApi } from "@/conection";
import { AxiosResponse } from "axios";
import { DateTime } from "ts-luxon";
import { AppModule } from "./app";

export type StatusType =
	| "danger"
	| "primary"
	| "secondary"
	| "success"
	| "info"
	| "warning";
export interface INotification {
	_id: string;
	agent: IAgent[];
	title: string;
	description: string;
	button: boolean;
	action: string;
	read: boolean;
	type: StatusType;
	icon?: string;
	secondTextBtn?: string;
	textBtn?: string;
	textCancel?: string;
	rightIconBtn?: string;
	handleClose?: () => boolean;
	handleAction?: () => boolean;
	answer?: string;
	createdAt: string;
	updatedAt: string;
}
export type NotificationPayload = Partial<Omit<INotification, "agent">> & {
	agent: string[];
};
function NotificationActions<T>(
	action: string,
	payload: unknown
): Promise<AxiosResponse<T>> {
	return coreApi.post(`api/notification/${action}`, payload);
}
@Module({ dynamic: true, namespaced: true, store, name: "notification" })
export class Notification extends VuexModule {
	_notifications: INotification[] = [];
	_loading = false;
	_showing = false;
	_notification: INotification | null = null;
	_all = false;
	_readed = 0;

	get notifications() {
		return this._notifications;
	}

	get showing() {
		return this._showing;
	}

	get notification(): INotification | null {
		return this._notification;
	}

	get loading() {
		return this._loading;
	}

	get readed() {
		return this._readed;
	}

	@Mutation
	SOCKET_NOTIFICATION_LIST(notifications: INotification[]) {
		this._loading = false;
		this._readed = !this._all
			? notifications.length
			: notifications.reduce((acc, curr) => (!curr.read ? acc++ : acc), 0);
		this._notifications = notifications.sort(
			(a, b) =>
				DateTime.fromISO(b.createdAt).valueOf() -
				DateTime.fromISO(a.createdAt).valueOf()
		);
	}
	@Mutation
	SOCKET_NOTIFICATION_NEW(notification: INotification) {
		this._readed++;
		this._notifications = [notification, ...this._notifications];
		AppModule.notificationsElement.NEW_NOTIFICATION?.play();
	}

	@Mutation
	SOCKET_NOTIFICATION_READ_CLOSE_TICKET(notification: INotification) {
		const index = this._notifications.findIndex(
			n => n._id === notification._id
		);
		this._notifications.splice(index, 1, notification);
		this._readed--;
	}

	@Mutation
	setLoading(b: boolean) {
		this._loading = b;
	}

	@Mutation
	show(b: boolean) {
		this._showing = b;
	}

	@Mutation
	setNotification(notification: INotification) {
		this._notification = notification;
		this._showing = true;
	}
	@Mutation
	setAll(all?: boolean) {
		this._all = all ?? false;
	}

	@Mutation
	updateNotification({
		idx,
		notification,
	}: {
		idx: number;
		notification: Partial<INotification>;
	}) {
		this._notifications[idx] = Object.assign(
			this._notifications[idx],
			notification
		);
	}
	@Mutation
	deleteNotification(idx: number) {
		this._readed--;
		this._notifications.splice(idx, 1);
	}

	@Action
	fetchNotifications(payload: { token: string; all?: boolean }) {
		this.setAll(payload.all);
		this.setLoading(true);
		socket.emit("NOTIFICATION_LIST", payload);
	}

	@Action
	async handleAction({
		action,
		payload,
		id: notificationID = this._notification?._id,
	}: {
		action: string;
		payload?: unknown;
		id?: string;
	}) {
		this.setLoading(true);
		const response = await NotificationActions<{ success: boolean }>(action, {
			notificationID,
			payload,
		});
		this.setLoading(false);
		if (response.data.success) {
			const idx = this.notifications.findIndex(n => n._id === notificationID);
			if (idx === -1) return;
			this.deleteNotification(idx);
			this.show(false);
		}
	}
}

export const NotificationModule = getModule(Notification);
