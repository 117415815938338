<template>
	<div class="timeline-block" :class="{ 'timeline-inverted': inverted }">
		<slot name="badge">
			<span
				class="avatar avatar-sm rounded-circle"
				v-if="(badgeIcon?.indexOf('http') ?? -1) > -1"
				style="width: 33px; height: 33px; position: absolute"
				:class="{
					'no-background': !((badgeIcon?.indexOf('http') ?? -1) > -1),
				}"
			>
				<img alt="Image placeholder" :src="badgeIcon" />
			</span>
			<span v-else class="timeline-step" :class="`badge-${badgeType}`">
				<i :class="badgeIcon"></i>
			</span>
		</slot>
		<div class="timeline-content">
			<slot></slot>
		</div>
	</div>
</template>
<script>
export default {
	name: "time-line-item",
	props: {
		inverted: Boolean,
		title: String,
		badgeType: {
			type: String,
			default: "success",
		},
		badgeIcon: {
			type: String,
			default: "",
		},
	},
};
</script>
<style></style>
