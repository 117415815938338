import { RouteRecordRaw } from "vue-router";
import { GuardRoles, IRouteRecordRaw } from "@/types/router";

const Omnichannel = () =>
	import(
		/* webpackChunkName: "Omnichannel_Layout" */ "./views/Omnichannel.vue"
	);

const DashboardLayout = () =>
	import(
		/* webpackChunkName: "Dashboard_Layout" */ "../views/Layout/DashboardLayout.vue"
	);

export const omnichannelRoutes: Exclude<RouteRecordRaw, "children"> & {
	children: IRouteRecordRaw[];
} = {
	path: "/omnichannel",
	component: DashboardLayout,
	redirect: "/omnichannel/messages/assigned",
	name: "Omnichannel",
	children: [
		{
			path: "messages/:option",
			name: "Messages",
			components: {
				default: Omnichannel,
			},
		},
	],
	meta: {
		guard: GuardRoles.AGENT,
	},
};
