import {
	getModule,
	Module,
	Mutation,
	VuexModule,
} from "vuex-module-decorators";
import AlertComponent from "@/components/Notification.vue";
import { POSITION, useToast } from "vue-toastification";
import { store } from "@/store";

export interface IAlert {
	type: AlertTypes;
	text: string;
}

export type AlertTypes = "error" | "success" | "info" | "warning";
export interface IAlertModule {
	alerts: IAlert[];
	autoShow: boolean;
}

@Module({ dynamic: true, namespaced: true, store, name: "alert" })
export class Alert extends VuexModule implements IAlertModule {
	alerts: IAlert[] = [];
	autoShow = true;

	static resolveType(type: AlertTypes) {
		return {
			error: { color: "danger", icon: "fas fa-exclamation-triangle" },
			info: { color: "info", icon: "fas fa-info-circle" },
			success: { color: "success", icon: "fas fa-check-circle" },
			warning: { color: "warning", icon: "fas fa-exclamation-triangle" },
		}[type];
	}

	get error(): IAlert {
		return this.alerts[0];
	}

	@Mutation
	setAutoShow(autoShow = false) {
		this.autoShow = autoShow;
	}

	@Mutation
	SOCKET_PUSH_NOTIFICATION({ text, type }: IAlert) {
		const toast = useToast();
		const { color, icon } = Alert.resolveType(type);
		if (this.autoShow)
			toast(
				{
					component: AlertComponent,
					props: {
						icon,
						text,
						type: color,
					},
				},
				{
					hideProgressBar: true,
					icon: false,
					closeButton: false,
					position: POSITION.BOTTOM_RIGHT,
				}
			);
		else this.alerts = [...this.alerts, { text, type }];
	}

	@Mutation
	shift() {
		this.alerts = this.alerts.slice(1, this.alerts.length);
	}

	@Mutation
	pop() {
		this.alerts = this.alerts.slice(0, this.alerts.length - 1);
	}
}

export const AlertModule = getModule(Alert);
