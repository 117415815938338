import * as Yup from "yup";

/* export interface ISchemaShare {

} */
const nameRex =
	/^[a-zñáéíóúA-ZÑÁÉÍÓÚ]+(([ ][a-zñáéíóúA-ZÑÁÉÍÓÚ])?[a-zñáéíóúA-ZÑÁÉÍÓÚ]*){0,4}$/;
const message = "Campo inválido";
const genericSchema = (value: string) => {
	return Yup.string()
		.trim()
		.nullable()
		.notRequired()
		.when(value, {
			is: (value: string | any[]) => value?.length,
			then: rule => rule.matches(nameRex, message),
		});
};
export const schemaShare = () =>
	Yup.object().shape(
		{
			first_name: genericSchema("first_name"),
			second_name: genericSchema("second_name"),
			third_name: genericSchema("third_name"),
			last_name: genericSchema("last_name"),
			last_name2: genericSchema("last_name2"),
			email: Yup.string().trim().email(),
			company_name: Yup.string().trim(),
			address: Yup.string().trim(),
		},
		[
			// Add Cyclic deps here because when require itself
			["first_name", "first_name"],
			["second_name", "second_name"],
			["third_name", "third_name"],
			["last_name", "last_name"],
			["last_name2", "last_name2"],
		]
	);
export const extensionSchema = () =>
	Yup.object().shape(
		{
			Extension: Yup.string().required(),
		},
		[["extension", "extension"]]
	);
