import { MessageFilePayload } from "vue-advanced-chat";
import * as yup from "yup";

export const userSchema = () =>
	yup.object().shape({
		username: yup
			.string()
			.trim()
			.min(5, "Ingrese como mínimo 5 caracteres")
			.max(25, "Ingrese como máximo 15 caracteres")
			.required("Ingrese un nombre de usuario"),
		name: yup
			.string()
			.trim()
			.matches(/^([a-zñáéíóúA-ZÁÉÍÓÚ]| )+$/, "Ingrese un nombre válido")
			.required("Ingrese un nombre de usuario"),
		code: yup.string().matches(/^[0-9]{3,5}$/, "Ingrese un código válido"),
		picture: yup.array().test("test-file", "", async function (value: any) {
			if (!value) return true;
			const val = (await value) as MessageFilePayload[];
			const valid = val?.every(f => /image/gi.test(f.type));
			return (
				valid ||
				this.createError({
					path: this.path,
					message: "Seleccione una imagen válida",
				})
			);
		}),
	});

export const passwordSchema = () =>
	yup.object().shape({
		password: yup
			.string()
			.matches(
				/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
				"La contraseña debe contener como mínimo 8 caracteres y con un número"
			)
			.required("Ingrese la contraseña"),
		rpassword: yup
			.string()
			.oneOf([yup.ref("password"), null], "Las contraseñas no son iguales!")
			.required("Confirme la contraseña"),
	});
