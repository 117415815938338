import { Template as ITemplate, ResponseTemplate } from "@/types/templates";
import {
	Action,
	getModule,
	Module,
	Mutation,
	VuexModule,
} from "vuex-module-decorators";
import { store } from "@/store";
import { coreApi } from "@/conection";
import { TicketModule } from "./Ticket";
import { indexWithArray } from "@/util/utils";
import { IndexType } from "@/types/store";

type Filters = {
	category: string;
	total: number;
	language: string;
	actual: string;
};
@Module({ name: "template", namespaced: true, dynamic: true, store })
export class Template extends VuexModule {
	_templates: IndexType<ITemplate[]>;
	_categories: Set<string> = new Set();
	_filters: Filters = {
		category: "",
		total: 0,
		language: "",
		actual: "",
	};
	_loading = false;

	// START: Getters
	get categories() {
		return this._categories;
	}
	get category() {
		return this._filters.category;
	}
	get templates(): [string, ITemplate[]][] {
		// Return a matrix for better improvement
		return Object.entries(this._templates ?? {}).filter(([, arrTemp]) =>
			this.category.length ? arrTemp[0].tags.indexOf(this.category) > -1 : true
		);
	}
	get filters() {
		return this._filters;
	}
	get rawTemplates() {
		return this._templates;
	}
	get nameTemplates(): string[] {
		return Object.keys(this._templates ?? {});
	}
	get loading() {
		return this._loading;
	}
	// END: Getters

	// START: Mutations
	@Mutation
	setTemplates(_templates: IndexType<ITemplate[]>) {
		this._templates = _templates;
	}
	@Mutation
	setFilter(payload: Partial<Template["_filters"]>) {
		this._filters = Object.assign(this._filters, payload);
	}
	@Mutation
	setCategory(category: string) {
		this._filters.category = category;
	}
	@Mutation
	setTotal(payload: number) {
		this._filters.total = payload;
	}
	@Mutation
	setLoading(payload: boolean) {
		this._loading = payload;
	}
	// END: Mutations

	// Start: Actions
	@Action
	async fetch() {
		try {
			this.setLoading(true);
			const response = await coreApi.get<ResponseTemplate>(
				"/api/ticket/templates",
				{
					params: {
						ticket: TicketModule.currentRoomID,
					},
				}
			);
			const templates: IndexType<ITemplate[]> = {};
			response.data.waba_templates.forEach(temp => {
				temp.tags.forEach(tag => this._categories.add(tag));
				indexWithArray(templates, temp, temp.name);
			});
			this.setLoading(false);
			this.setTemplates(templates);
		} catch (err) {
			console.log(err);
		}
	}
	/* 	@Action
	async send() {
		socket.
	} */
	// END: actions
}

export const TemplateModule = getModule(Template);
