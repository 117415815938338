//Interfaces
export interface IControl {
	pausesReason?: IPause[];
	agentState: IAgentState;
}
export interface IPause {
	tittle: string;
	value: string;
	time?: string;
	totalTime?: string;
}

export interface IAgentState {
	status: IStatus;
	pause?: IPause;
}

export enum Filters {
	OPEN = "open",
	CLOSED = "closed",
	CLAIMED = "claimed",
	ARCHIVED = "archived",
	BOT = "bot",
	OVERVIEW = "overview",
}
export enum IStatus {
	onpause = 1,
	ready,
}
