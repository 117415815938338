import {
	Action,
	Module,
	Mutation,
	VuexModule,
	getModule,
} from "vuex-module-decorators";
import { store } from "@/store";
import { coreApi } from "@/conection";

export interface IIdentificationModule {
	foundCoincidences: boolean;
	coincidendes: Array<any>;
}

@Module({ dynamic: true, store, name: "identification" })
export class Identification
	extends VuexModule
	implements IIdentificationModule
{
	foundCoincidences = false;
	coincidendes: Array<any>;

	get FoundCoincidences() {
		return this.foundCoincidences;
	}

	get Coincidendes() {
		return this.coincidendes;
	}

	@Mutation
	setFoundCoincidences(foundCoincidences: boolean) {
		this.foundCoincidences = foundCoincidences;
	}

	@Mutation
	setCoincidendes(coincidendes: Array<any>) {
		this.coincidendes = coincidendes;
	}

	@Action
	async requestFoundCoincidences({
		client,
		identifications,
	}: {
		client: string;
		identifications: Array<string>;
	}) {
		const payload = {
			client,
			identifications,
		};
		try {
			return coreApi
				.post("/api/client/find-coincidences", payload)
				.then(response => {
					this.setFoundCoincidences(response.data.coincidences > 0);
				});
		} catch (error) {
			console.log(error, "ERROR");
		}
	}
}

export const IdentificationModule = getModule(Identification);
