import { Field } from "./components";

// eslint-disable-next-line
export type IState<T extends { [key: string]: any }> = T;

export type TypedState<T extends { [key: string]: any }> = (
	state: T
) => IState<T>;

export type IndexType<T> = {
	[k: string]: T;
};
export type DoubleIndexType<T> = {
	[k: string]: IndexType<T>;
};
export type TripleIndexType<T> = {
	[k: string]: DoubleIndexType<T>;
};

export interface ITag {
	_id: string;
	nombre: string;
	descripcion: string;
	color: string;
	activo: boolean;
}
export interface IStatus {
	_id: string;
	active: boolean;
	description: string;
	interval: number;
	subject: string;
}

export interface IOrganization {
	_id: string;
	name: string;
	friendly_name: string;
	picture: string;
	tags: ITag[];
	active: boolean;
	createdAt: Date;
	updatedAt: Date;
	__v: number;
	status: IStatus[];
}
export interface IAgentGroup {
	_id: string;
	active: boolean;
	name: string;
	native_id: number;
}

export interface IConfig {
	name: string;
	keys: string[];
	data: Field[];
	organization: IOrganization;
}

export interface IToken {
	id: string;
	name: string;
	username: string;
	picture: string;
	organization: IOrganization;
	agentGroup:IAgentGroup;
	code: string;
	iat: number;
	exp: number;
}

export enum Session {
	VERIFIED = 0,
	INVALID,
	UNVERIFIED,
	VERIFYING,
}
