import axios, { AxiosResponse } from "axios";
import { IAgent, IResponseJSON } from "./interface";
import { parseResponse } from "@/util/data";
import { env } from "@/config/index";
import { IRootState } from "@/store";

import qs from "qs";
import { Store } from "vuex";

export const dashboardClient = axios.create({
	baseURL: env.DashboardApi,
	timeout: 10000,
	auth: {
		username: env.dashboardUsername,
		password: env.dashboardPassword,
	},
});

export const callCenterClient = axios.create({
	withCredentials: true,
	headers: {
		"content-type": "application/x-www-form-urlencoded",
		// 'Access-Control-Allow-Credentials': 'false'
	},
	baseURL: env.AxUrl,
	timeout: 20000,
});
export const simpleCallCenterClient = axios.create({
	withCredentials: true,
	baseURL: env.AxUrl,
	timeout: 50000,
});
export const omniChannelClient = axios.create({
	baseURL: env.OmniUrl,
	auth: {
		username: env.OmniUsernameAgent,
		password: env.OmniPasswordAgent,
	},
});
export const coreApi = axios.create({
	baseURL: env.CoreApi,
});
//Connection with DMS API
export const DMSAPI = axios.create({
	withCredentials: false,
	baseURL: env.DMSAPI,
	timeout: 20000,
	auth: {
		username: env.DmsApiUser,
		password: env.DmsApiPass,
	},
});

let res: IResponseJSON = {
	status: "Error",
	message: "El servidor respondio con error",
};

function loginCallCenter(agente: IAgent) {
	// const querystring = require("querystring");
	callCenterClient({
		method: "post",
		url: "controllers/auth.php",
		//data: new URLSearchParams(agente as unknown as Record<string, string>),
		data: qs.stringify(agente),
	})
		.then(function (response: AxiosResponse) {
			res = parseResponse(response);
		})
		.catch(function (error: unknown) {
			console.log(error);
		});
	return JSON.stringify(res);
}

function closeSession() {
	callCenterClient({
		method: "POST",
		url: "controllers/closesession.php",
	}).then(function (response: AxiosResponse) {
		res = parseResponse(response);
	});
	return JSON.stringify(res);
}
export { loginCallCenter, closeSession };

export const apiEnable = (str: Store<IRootState>) => {
	/* 	coreApi.defaults.headers.common["access-token"] =
		str.state.auth?.data?.accessToken ?? ""; */
	str.subscribe(mutation => {
		if (mutation.type === "auth/AccessToken")
			coreApi.defaults.headers.common["access-token"] = mutation.payload;
	});
};
