import { Room } from "vue-advanced-chat";
import { Filters } from "./control";
import { IMessage, IRoom } from "./messages";
import { IndexType } from "./store";

export interface ITicketView {
	createdAt: string;
	lastMessageDate: string;
	interface: string;
	color: string;
	_id: string;
}

export interface IAgent {
	active: boolean;
	auth: { [key: string]: string };
	connected: boolean;
	createdAt: string;
	name: string;
	organization: string;
	pausa_actual: string | null;
	picture: string;
	updatedAt: string;
	__v: number;
	_id: string;
}
// Nombre de iconos de FA5
export enum Interfaces {
	TWITTER = "fab fa-twitter",
	WHATSAPP_PUP = "fab fa-whatsapp",
	WHATSAPP_OFFICIAL = "fab fa-whatsapp",
	FACEBOOK_MESSENGER = "fab fa-facebook-messenger",
	CALL_CENTER = "fab fa-viber",
	INSTAGRAM = "fab fa-instagram",
	IMAP_SMTP_EMAIL = "fab fa-envelope",
	WEBCHAT_INTERFACE = "fab fa-weixin",
	COMMENT_INTERFACE = "fab fa-facebook",
}

export enum InterfacesName {
	TWITTER = "Twitter",
	WHATSAPP_PUP = "Whatsapp Business",
	WHATSAPP_OFFICIAL = "Whatsapp",
	FACEBOOK_MESSENGER = "Messenger",
	INSTAGRAM = "Instagram",
	IMAP_SMTP_EMAIL = "Email",
	WEBCHAT_INTERFACE = "Web Chat",
	COMMENT_INTERFACE = "Comentarios facebook",
	CALL_CENTER = 'Call center'
}

export enum InterfacesColor {
	TWITTER = "info",
	twitter = "info",
	IMAP_SMTP_EMAIL = "warning",
	envelope = "warning",
	INSTAGRAM = "danger",
	instagram = "danger",
	FACEBOOK_MESSENGER = "primary",
	"facebook-messenger" = "primary",
	WEBCHAT_INTERFACE = "purple",
	weixin = "purple",
	WHATSAPP_PUP = "success",
	WHATSAPP_OFFICIAL = "success",
	whatsapp = "success",
	COMMENT_INTERFACE = "info",
	CALL_CENTER = "yellow",
}
export enum TicketsStatusColor {
	open = "warning",
	claimed = "success",
	closed = "danger",
	archived = "success",
	bot = "success",
	w_rate = "info",
	overview = "",
}
export enum TicketsStatus {
	open = "En espera de agente",
	claimed = "Atención en progreso",
	closed = "Finalizado",
	archived = "Archivado",
	bot = "En asistente virtual",
	w_rate = "En calificación",
	overview = "",
}

export type InterfaceId = keyof typeof Interfaces;

export interface IChannel {
	account: string;
	active: boolean;
	color: string;
	createdAt: string;
	interface: InterfaceId;
	name: string;
	organization: string;
	picture: string;
	updatedAt: string;
	cId: string;
	expireAt: Date;
	__v: number;
	_id: string;
}

export interface IClientChannel {
	channel: string;
	main: boolean;
	name: string;
	picture: string;
	service_id: string;
	cId: string;
	expireAt: Date;
	replied: boolean;
	_id: string;
}

export interface IClientCustomer {
	name: string;
	full_name?: string;
	first_name?: string;
	second_name?: string;
	third_name?: string;
	last_name?: string;
	last_name2?: string;
}

export interface IClient {
	channels: IClientChannel[];
	contacts: unknown[];
	createdAt: Date | string;
	customer: IClientCustomer;
	identification: string[];
	dynamicData?: IndexType<string | number>;
	organization: string;
	phones: unknown[];
	picture: string;
	tags: unknown[];
	updatedAt: string;
	__v: number;
	_id: string;
}

export interface IWallet {
	account: string;
	active: boolean;
	agent: string;
	createdAt: string;
	name: string;
	organization: string;
	updatedAt: string;
	__v: number;
	_id: string;
}

export interface ITicket {
	account: string;
	agent: IAgent[];
	agent_contacted: boolean;
	bot: boolean;
	channel: IChannel;
	client: IClient[];
	comentario?: string;
	pinned: string[];
	contacted: boolean;
	contacted_first: string;
	createdAt: Date | string;
	duration: number;
	first_msg: string;
	first_msg_date: string;
	interface_data: unknown;
	isGroup: boolean;
	rate: { score: number; feedback: string };
	last_msg: IMessage;
	last_msg_date: string;
	last_bot: string;
	organization: string;
	status: Filters;
	subject: string;
	subject_picture: string;
	subtipificacion?: string;
	tags: unknown[];
	data: IndexType<any>;
	states: {
		claimed_at: string;
		closed_at: string;
	};
	thread: string;
	tipificacion?: string;
	archived?: boolean;
	private_replied?: boolean;
	public_replied?: boolean;
	updatedAt: string;
	__v: number;
	_id: string;
}

export interface ITicketModule {
	tickets: ITicket[];
	mapTickets: {
		[k in Filters]: ITicket[];
	};
	mapRooms: {
		[k in Filters]: IRoom[];
	};
	lastTicketsFromClient: ITicket[];
	rooms: Room[];
	wallets: IWallet[];
	search: string;
}
