import {
	getModule,
	Module,
	Mutation,
	VuexModule,
} from "vuex-module-decorators";
import { store } from "@/store";
import { AuthModule } from "./Auth";

export interface ISubTypification {
	subtipificacion: string;
	_id: string;
}

export interface ITypification {
	active: boolean;
	createdAt: string;
	organization: string;
	subtipificacion: ISubTypification[];
	tipificacion: string;
	updateAt: string;
	__v: number;
	_id: string;
}

export interface ITypificationModule {
	typifications: ITypification[];
}

@Module({ dynamic: true, store, name: "typification" })
export class Typification extends VuexModule implements ITypificationModule {
	typifications: ITypification[] = [];

	get Typifications() {
		return this.typifications.filter(
			t => t.organization == AuthModule.organizationID && t.active
		);
	}

	@Mutation
	SOCKET_TIPIFICACION_LIST(typifications: ITypification[]) {
		console.log("Entra en socket tipification list")
		this.typifications = typifications;
	}
}

export const TypificationModule = getModule(Typification);
