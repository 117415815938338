<template>
	<div class="form-group" :class="groupClasses">
		<slot name="label">
			<label v-if="label && !bottomLabel" :class="labelClasses">
				<small v-if="smallLabel" class="text-muted">{{ label }}</small>
				<template v-else> {{ label }}</template>
				<span v-if="required">*</span>
			</label>
		</slot>

		<div
			:class="[
				{
					'has-danger': !!errorMessage,
					'input-group': hasIcon,
					'input-group-alternative': alternative,
					focused: focused,
					'has-label': label || $slots.label,
					[`input-${size}`]: size,
				},
				inputGroupClasses,
			]"
		>
			<div v-if="addonLeftIcon || $slots.addonLeft" class="input-group-prepend">
				<span class="input-group-text">
					<slot name="addonLeft">
						<i :class="addonLeftIcon"></i>
					</slot>
				</span>
			</div>
			<slot
				:data="{
					name,
					type,
					placeholder,
					required,
					disabled,
					inputValue,
					handleChange,
					focused,
					inputClasses,
				}"
			>
				<el-input
					v-if="type === 'textarea'"
					type="textarea"
					:autosize="{ minRows: 2 }"
					:name="name"
					:id="name"
					:placeholder="placeholder"
					:required="required"
					:disabled="disabled"
					:value="inputValue"
					v-model:model-value="inputValue"
					@input="handleChange"
					@blur="focused = false"
					@focus="focused = true"
					:class="inputClasses"
				/>
				<input
					v-else
					class="form-control"
					:name="name"
					:id="name"
					:type="type"
					:placeholder="placeholder"
					:required="required"
					:disabled="disabled"
					:value="inputValue"
					@input="handleChange"
					@blur="focused = false"
					@focus="focused = true"
					:class="inputClasses"
				/>
			</slot>
			<div
				v-if="addonRightIcon || $slots.addonRight"
				class="input-group-append"
			>
				<span class="input-group-text">
					<slot name="addonRight">
						<i :class="addonRightIcon"></i>
					</slot>
				</span>
			</div>
		</div>
		<slot name="infoBlock"></slot>
		<slot name="helpBlock">
			<small v-if="smallLabel && label && bottomLabel" class="text-muted">{{
				label
			}}</small>
			<div
				class="text-danger invalid-feedback"
				style="display: block"
				:class="{ 'mt-2': hasIcon }"
				v-show="errorMessage"
			>
				{{ errorMessage }}
			</div>
		</slot>
	</div>
</template>

<script>
import { useField } from "vee-validate";

export default {
	name: "base-input",
	props: {
		addonRightIcon: String,
		addonLeftIcon: String,
		append: String,
		type: {
			type: String,
			default: "text",
		},
		value: {
			type: String,
			default: "",
		},
		placeholder: {
			type: String,
			default: "",
		},
		name: {
			type: String,
			required: true,
		},
		label: {
			type: String,
		},
		required: {
			type: Boolean,
		},
		disabled: {
			type: Boolean,
		},
		smallLabel: {
			type: Boolean,
		},
		validator: {
			type: Object,
			default: undefined,
		},
		bottomLabel: {
			type: Boolean,
		},
		alternative: {
			type: Boolean,
			description: "Whether input is of alternative layout",
		},
		labelClasses: {
			type: String,
			description: "Input label css classes",
			default: "form-control-label",
		},
		groupClasses: {
			type: String,
			description: "Input group css classes",
			default: "",
		},
		inputGroupClasses: {
			type: String,
			default: "",
		},
		group: {
			type: String,
			default: "",
		},
		size: {
			type: String,
			description: "Input size",
			validator(value) {
				let acceptedValues = ["", "sm", "lg", "xl"];
				return acceptedValues.indexOf(value) !== -1;
			},
		},
		inputClasses: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			focused: false,
		};
	},
	computed: {
		hasIcon() {
			const { addonRight, addonLeft } = this.$slots;
			return (
				addonRight !== undefined ||
				addonLeft !== undefined ||
				this.addonRightIcon !== undefined ||
				this.addonLeftIcon !== undefined ||
				this.group
			);
		},
	},
	watch: {
		value(n) {
			this.inputValue = n;
		},
	},
	setup(props) {
		const {
			value: inputValue,
			errorMessage,
			handleChange,
			meta,
		} = useField(props.name, props.validator, {
			initialValue: props.value,
		});

		return {
			handleChange,
			errorMessage,
			inputValue,
			meta,
		};
	},
};
</script>

<style></style>
