import {
	getModule,
	Module,
	Mutation,
	VuexModule,
} from "vuex-module-decorators";
import { store } from "@/store";
import { TicketModule } from "./Ticket";
import { InterfaceId } from "@/types/tickets";

@Module({ dynamic: true, store, name: "conversation", namespaced: true })
export class Conversation extends VuexModule {
	id: string;
	expireAt: Date | undefined;
	timer: NodeJS.Timeout;
	expire = false;
	userReply = false;
	active = false;
	official = false;

	get showToggle() {
		return !this.expire && this.userReply && this.official;
	}
	get isExpired() {
		return this.expire;
	}
	get showControl() {
		return (this.expire || !this.userReply || this.active) && this.official;
	}

	@Mutation
	setActive(payload: boolean) {
		this.active = payload;
	}
	@Mutation
	setExpired(payload: boolean) {
		if (!payload) return ConversationModule.clearConversation();
		// console.log("ids: ", this.id, TicketModule.currentRoom?.roomId);
		// console.log(ticketM);
		this.expire =
			this.id === (TicketModule.currentRoom?.cId ?? "") &&
			/* TicketModule.currentRoom?.expireAt?.getTime() ?? */ 0 - Date.now() < 0;
	}

	@Mutation
	setConversation(payload: {
		id: string;
		expireAt: Date | undefined;
		replied?: boolean;
		interface?: InterfaceId;
	}) {
		// Verifica que el actual ticket tenga fecha de expiración si tiene es porque la sesión se vence
		if (!payload.expireAt && payload.interface !== "WHATSAPP_OFFICIAL")
			return this.id !== payload.id
				? ConversationModule.clearConversation()
				: {};
		// Verifica que no haya token update del mismo chat para no recargar el timer
		const expireAtDiff =
			(payload.expireAt?.getTime() ?? 0) - (this.expireAt?.getTime() ?? 0);

		if (
			this.id === payload.id &&
			expireAtDiff < 10 &&
			this.userReply === payload.replied
		)
			return;
		// Si es nuevo limpia el timer
		clearTimeout(this.timer);
		// Actualiza propiedades
		this.id = payload.id;
		this.official = true;
		this.expireAt = new Date(payload.expireAt ?? 0);
		this.userReply = Boolean(payload.replied);
		this.expire = Boolean(
			!payload.expireAt ||
			(this.id === (TicketModule.currentRoom?.cId ?? "") &&
				payload.expireAt.getTime() - Date.now() < 0)
		);
		this.active = this.expire || !this.userReply;
		// Si ya expiró solo finalizar
		if (this.expire) {
			return;
		}
		// Si está pendiente de finalizar calcular el ttl
		const diff = this.expireAt.getTime() - Date.now();
		if (diff > 0)
			this.timer = setTimeout(() => ConversationModule.setExpired(true), diff);
		else ConversationModule.setExpired(true);
	}

	@Mutation
	clearConversation() {
		this.id = "";
		this.expireAt = undefined;
		this.expire = false;
		this.userReply = true;
		this.active = false;
		this.official = false;
		clearTimeout(this.timer);
	}
}

export const ConversationModule = getModule(Conversation);
