import {
	Module,
	VuexModule,
	getModule,
	Mutation,
	Action,
} from "vuex-module-decorators";
import { AxiosResponse } from "axios";
import { IClientData } from "@/types/campaign";
import { AlertModule } from "@/store/modules/Alert";
import { DMSAPI } from "@/conection";
import { alertMessages } from "@/util/consts";
import { Module as Mod } from "vuex";
import { IRootState, store } from "@/store";

export interface ICampaing {
	clientData: IClientData;
	showDrawer: boolean;
}

@Module({ dynamic: true, namespaced: true, store, name: "campaing" })
export class Campaing extends VuexModule implements ICampaing {
	constructor(module: Mod<ThisType<ICampaing>, IRootState>) {
		super(module);
	}
	clientData = {
		comentario: "",
		datos: {},
	};
	showDrawer = false;
	get getClientData() {
		return this.clientData;
	}
	get getShowDrawer() {
		return this.showDrawer;
	}
	@Mutation
	setShowDrawer(e: boolean) {
		this.showDrawer = e;
	}
	@Mutation
	setClientData(client: IClientData) {
		this.clientData = client;
	}

	@Action
	async loadClientData(payload: any) {
		try {
			const DMSResponse: AxiosResponse = await DMSAPI({
				method: "post",
				url: `/campaign/number`,
				data: payload,
			});
			if (DMSResponse.status == 201 || DMSResponse.status == 200) {
				this.setClientData(DMSResponse.data);
			} else {
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: alertMessages.ERROR_UPDATE,
					type: "error",
				});
			}
		} catch (error) {
			console.error(error, "Update user error");
		}
	}
}

export const CampaingModule = getModule(Campaing);
