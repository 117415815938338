<template>
	<div
		class="alert alert-dismissible fade show d-flex"
		:class="'alert-' + type + ' ' + $attrs.class"
		data-notify="container"
	>
		<button
			type="button"
			@click="click"
			data-dismiss="alert"
			aria-label="Close"
			class="close"
		>
			<span aria-hidden="true">×</span>
		</button>
		<span
			data-notify="icon"
			class="alert-icon d-flex align-items-center mr-3"
			v-if="icon && !ownIcon"
			:class="icon"
		></span>
		<span
			data-notify="icon"
			class="alert-icon d-flex align-items-center"
			v-else
			:class="ownIcon"
		></span>
		<span data-notify="message" v-if="text && !ownText" v-html="text"></span>
		<span data-notify="message" v-else v-html="ownText"></span>
	</div>
</template>
<script>
export default {
	name: "notification",
	props: {
		bold: String,
		ownText: String,
		text: String,
		type: String,
		icon: String,
		ownIcon: String,
	},
	methods: {
		click(e) {
			this.$emit("close", e);
		},
	},
};
</script>
<style lang="scss"></style>
